<template>
<SeatHeader :currentshow="currentShow"></SeatHeader>

<main>
  <!-- <div :class="poceedBooking?'metaportal_fn_wallet_closer ready':' metaportal_fn_wallet_closer ready active'"> -->
  <SiteModal></SiteModal>
  <!-- </div> -->
<section class="content-section" data-background="#ffffff" style="background: rgb(242, 242, 242) none repeat scroll 0% 0%;padding-top: 10px; padding-bottom: 50px;">
  <div class="container-fluid">
      <div class="row">
       <div class="move-container" style="padding-left: 10px; padding-right: 10px" v-if="!showTimeLoader">
        <div
      style="float: left; padding-top: 13px; margin-right: 10px; color: rgb(19, 19, 19)"
     >
      Movie Time 
    </div>
      <SeatTime :showtimes="currentShowtime" ></SeatTime>
    </div>
<SeatLayout :showId="$route.params.showid" ref="layoutComp" @seatclicked="showSeats" ></SeatLayout>
<div class="col-md-3" style="color: #000000;"> 
<div> <h3 class="fn__maintitle big te" data-text="Blog Articles" data-align="center" style="font-size: 25px;">Show Details </h3></div>
<span class="">Theatre</span>
<div class="tecnam">{{ currentShow.theatre}}</div>
<span class="">Screen</span>
<div class="tecnam">{{ currentShow.screen}} [{{ currentShow.exp}}]</div>
<span class="">Movie</span>
<div class="tecnam ">{{ currentShow.movie}}</div>
<span class="">Timing</span>
<div class="tecnam ">{{ currentShow.showtime}}</div>
<span class="categories">Seat Nos</span>
<div class="tecnam blu"><div v-if="selSeats.length"> 
<span class="bt" v-for="(seat,key) in selSeats" :key="key">{{seat.name}} </span>
</div></div>
<button class="accordion buttonan active" v-if="selSeats.length"  @click="priceDetails=!priceDetails">
<div class="row">
<div class="col-5 text-left" style="
padding: 0px;">
<a href="#" class="whtcol" >{{selSeats.length}} Seat</a>
</div><div class="col-2 text-center whtcol martop10"><font-awesome-icon icon="fa-solid fa-arrow-down" aria-hidden="true" />
</div>
<div class="col-5 text-right whtcol"  >{{selectedAmount()}}
</div></div>
</button>
<div class="pad15" v-if="priceDetails">
<div class="row padtp5" >
  <div class="col-7  nopad ">Total Ticket Fee:</div><div class="col-5 nopad text-right">{{selSeats.length}} × {{ selSeats.length?selSeats[0].prc:0}}</div></div>
<div class="row padb10"><div  class="col-9 nopad ">Convenience fees  <br><small>(Inc.GST)</small></div><div  class="col-3  nopad text-right">{{selSeats.length}} × {{ selSeats.length?selSeats[0].resamt:0}}</div></div>
<div class="row padb10" v-if="this.selSeats[0]?this.selSeats[0].tdamt:0"><div  class="col-9 nopad ">3D Glass</div><div  class="col-3  nopad text-right">{{selSeats.length}} × {{ selSeats.length?selSeats[0].tdamt:0}}</div></div>
<div class="row nopad tota"><div  class="col-7 nopad ">Total Fare</div><div  class="col-5  nopad text-right" >{{selectedAmount()}}
</div></div>
</div>
<div>
<form class="padd10" name="frmTicket" id="frmTicket">
<div>
<label class="tecname padtop14">Name *</label>
<input class="fulwid pad7 txtCFname" id="txtCFname" type="text" v-model="customer.name">
</div> 
<div>
<label class="tecname padtop14">Email *</label>
<input class="fulwid pad7 txtCFname" id="txtCFname" type="text" v-model="customer.email">
</div> 
<div>
<label class="tecname">Mobile No *</label>
<input class="fulwid pad7 txtCMobile" id="txtCMobile" type="text" v-model="customer.mobile">
</div>
<div style="color:#1a1add ;font-size:13px;margin-top: 5px;text-align: justify;"><span style="width:100%;font-weight: bold;color: #ff001b;">Attention!!</span><div style="color: #191919;">
Successful Transactions are provided with Booking ID &amp; Ticket details (Seat, Show Details... etc.) through E-mail and SMS from chithra cineplex. Other transactions are Considered Failed and will be Auto refunded from Payment Gateway.</div></div>
<button type="button" @click="bookTicket"  class="btn btn-danger btun marg15 nextBtn bk fulwid" style="text-transform: uppercase;" v-if="selSeats.length">{{orderClicked?"Please wait..":"Continue"}} </button>
<button type="button"  class="btn btn-primary btun marg10 btncom fulwid" style="text-transform: uppercase;" @click="this.$router.push('/')">Cancel </button>

<div id="msgWrap" v-if="notify" v-html="notify"></div>
</form>
</div>
 </div>
  </div>
  </div>
</section>
</main>
 
<div :class="'modal-mask'+(offline?' show':'')" @click="offline=false"><div class="modal-wrapper"><div class="modal-container"><h5 class="text-primary text-center">SORRY</h5><div class="text-danger">There is some network issue at this cinema. Please try after some time.</div></div></div></div>

<div :class="'modal-mask'+(exist?' show':'')" @click="exist=false"><div class="modal-wrapper"><div class="modal-container"><h5 class="text-primary text-center">SORRY</h5><div class="text-danger">Selected Seats already booked from another location. Please try again</div></div></div></div>
</template>

<script>
import axios from 'axios';
import { URL,EBKEY } from '@/Conf';
import SeatHeader from './SeatHeader.vue';
import SeatTime from '../MovieDetails/ShowTime.vue';
import SeatLayout from './SeatLayout.vue';
import SiteModal from '../UI/SiteModal.vue';
export default {
    components:{
        SeatHeader,
        SeatTime,
        SiteModal,
        SeatLayout,
    },
    data:()=>({
    offline:false,  
    exist:false,
    seatLoaded:false,  
    showId:null,
    layoutLoading:true,
    showTimeLoader:true,
    priceDetails:false,
    currentShow:{},
    Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
      days:{"0":"Sun","1":"Mon","2":"Tue","3":"Wed","4":"Thu","5":"Fri","6":"Sat"},
      months:{"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"},
      showDates:[],
      showTimes:[],
      bookedSeats:{},
      currentShowtime:[],
      datePages:{},
      selSeats:[],
      customer:{
            loginCode:localStorage.getItem("loginCode"),
            mobile:null,
            email:null,
            name:null,},
      notify:null,
      orderClicked:false,
    }),
    methods:{
      getCurrentShow(){
        this.selSeats=[];
        this.showId=this.$route.params.showid;
        axios.get(URL + "showDetail/"+this.$route.params.showid).then((response) => {
        this.currentShow = response.data.result;
      }).then(()=>{
        axios.get(URL + "otherShows/"+this.$route.params.showid).then((response) => {
        this.showTimes = response.data.result.showtime;
        this.bookedSeats=response.data.result.booked;
        this.showDates=Object.keys(this.showTimes);
        this.datePages=[];
        this.lastPage=this.showDates.length;
        for(var i=0;i<this.lastPage;i++){
         var splited=this.showDates[i].split("-");
           var pg={"day":this.days[splited[3]]+" "+splited[2],"mth":this.months[splited[1]]}
           this.datePages.push(pg); 
        }
        this.currentShowtime=this.showTimes[this.showDates[0]];
        if(this.showTimes){
              for(let stime of this.currentShowtime){
              stime["booked"]=this.bookedSeats[stime.id];
              }}
        this.showTimeLoader=false;
      })
      })
    },
    showSeats(selected){
      this.selSeats=selected;
    },
    selectedAmount(){
      let amount=0;
      this.selSeats.forEach((seat)=>{
       amount+=seat.prc+seat.tdamt+seat.resamt;
      }); 
      return "Rs" +amount;
    },
    bookTicket(){
      var myshow=this.$route.params.showid
      var seats=this.selSeats;
      let valid=true;
      let ermsg="<div class='text-danger'>";
      if(!this.customer.name){
        valid=false;
        ermsg+='Please Enter your name<br>';
      }
      //eslint-disable-next-line
      var emlexp=/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (!emlexp.test(this.customer.email)) {
        valid=false;
        ermsg+='Please enter valid email ID<br>';
      }
      
      const filter = /^\d{10}$/;
      //eslint-disable-next-line
      if (!filter.test(this.customer.mobile)) {
      valid=false;
      ermsg+="Enter a valid 10 digit mobile number (without leading zero and +91)</br>";
      }
      this.notify=ermsg;
      if(!valid){
      ermsg+="</div>";
      this.notify=ermsg;
      return 
      }
      this.orderClicked=true;
      axios.post(URL + "bookTicket",{showid:myshow,seats:seats,customer:this.customer},{headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }}).then((response) => {
          this.orderClicked=false;
          if(response.data.result=="offline"){
            this.offline=true;
          }
          if(response.data.result=="error"){
            this.exist=true;
            this.selSeats=[];
            this.$refs.layoutComp.selectedSeats=[];
          }
         if(response.data.easebuzz)
         {
         var jsn=JSON.parse(response.data.easebuzz);

          if(jsn.status==1)
          {
            var accessKey=jsn.key;
            var ebCheckout = new window.EasebuzzCheckout(EBKEY, 'prod');
   var options = {
           access_key: accessKey,
           onResponse: (response) => {
         if(response.status=='success'){
           axios.post(URL+"ticket-status",response,{headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }}).then((response) => {
          if(response.data.status=="done"){
            this.bookingDetail=response.data.details;
            this.$router.push('/my-mticket/'+response.data.num);
            this.isLoaded=true;
            this.isBderror=false;
          }
          if(response.data.status=="error"){
            this.isBderror=true;
            this.errmsg=response.data.msg;
          }
        });
         }else{
          this.$router.push('/ticket-failed');
         }
           },
           theme: "#941b1e"
          }
          ebCheckout.initiatePayment(options);
         }
       }
      })
    },
    getUser(){
      axios
        .get(URL + "checkLogin?code="+localStorage.getItem("loginCode"))
        .then((response) => {
          if(response.data.status=="done"){
            this.customer=response.data;
           
          }
        })
    }
  },
    
    updated(){
    },
    created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.getCurrentShow();
      }
    )
  },
    mounted(){
      this.getCurrentShow();
      this.getUser();

      var scripts=[
        { "src":"https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js"}
      ]
      for(let script of scripts){
        let attr=Object.keys(script);
        let vals=Object.values(script);
      let Script = document.createElement("script");
      for(let index in attr){
       Script.setAttribute(attr[index],vals[index]);
      }
      document.head.appendChild(Script);
    }
    }
}
</script>

<style type="text/css" media="screen">


@media only screen and ( max-width : 768px  ) 
{

.screen {width: 100%;}

}



.seat-wrapper .btn-seatbook, .seat-wrapper .btn-damaged {
  cursor: not-allowed;
  transition: all 300ms ease-in-out;
  background: #e10018;
  color: #fff0;
}

.seat-wrapper .btn-seat {
  transition: all 300ms ease-in-out;
  background-color: #cacaca;
  color: #cacaca;
}

.seat-wrapper .btn-seat-selected {
  background: #6feaf6 !important;color: #000 !important;font-size: 8px !important;
}

.seat-wrapper button {
  font-size: 8px;
  background-color: #444451;
  height: 16px;
  width: 19px;
  margin: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  fill: #444451;
  border: none;
  padding: 2px 0px 0px 0px;
}

.class-head {
  border-bottom: 1px solid #00000087;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 0 5px 0;
  text-align: center;
  color: #000000;
}

.seat-wrapper .bt-label {
  color: #000;
  background: none !important;
  line-height: 1;
  font-size: 1.1em;
  font-weight: bold;
}
.pr {
  font-size: 30px;
  color: #febb17;
  font-weight: 600;
}
.disbk1 {
  display: block;
}
.disno1 {
  display: none;
}
  @media only screen and (max-width: 768px){
.disno1 {
  display: block;
}
.seat-wrapper .bt-label {
  color: #fff;
  background: none !important;
  line-height: 1;
  font-size: 11px;
  font-weight: bold;
}
.seat-wrapper button {height: 12px;
width: 15px;
margin: 2px;}
}

.btn-rounded{background-color:#fff;border-radius:50%!important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; width:50px;height:50px;border:none}
</style>