<template>
  <div :class="isLoaded || isBderror?'metaportal_fn_wallet_closer ready':' metaportal_fn_wallet_closer ready active'">
  <div class="loaderWraper"><img :src="imgPath+'assets/img/loader.gif'" width="100"></div>
</div> 
  <SiteProgress></SiteProgress>
  <SiteHeader></SiteHeader>
<div class="container small" style="background-color: #ffffff;" v-if="isBderror">
    <div class="metaportal_fn_mint_top row pad50">
      <h1>OOpss!</h1>
      <h4>{{ errmsg}}</h4>
    </div>
  </div>
  <div class="container small" style="background-color: #ffffff;" v-if="isLoaded">
    <div class="metaportal_fn_mint_top row pad50">
      <section
        class="content-section"
        style=" padding-bottom: 5px"
      >
        <div class="container">
          <h1 class="suche" v-if="!this.ticketId">Booking Successful !!!</h1>
          <div class="susub">
            <!--  Your Movie  -->
            <span style="color: #fff">{{bookingDetail.movie}}</span> Awaits @ {{ bookingDetail.location}}!!!
          </div>
        </div>

        <div class="container"  >
    <div class="" style="width:auto;display:flex;margin:5px auto;padding:5px;">
      <div id="htmltoimage">
        <div class="ticket"  style="width:auto">
          <div class="stub sub1" >
            <div class="top" style="text-align: center; padding-top: 10px">
              <span class="num">
                <span class="admit">Ticket ID - </span>
                <span style="font-weight: 600"> {{ bookingDetail.resNo}}</span>
              </span>
            </div>

            <div style="text-align: center; padding-top: 20px">
              <img
                :src="bookingDetail.qrData"
                style="width: 63%"
              />
            </div>


          </div>
          <div class="check">
            <div class="big suched">{{bookingDetail.movie}}</div>
            <div
              class="number"
              style="
                position: absolute;
                top: 25px;
                right: 25px;
                color: #ec9120;
                font-size: 40px;
              "
            >
              <span class="succset"> Seat Nos </span>
              <span class="succtic">{{ bookingDetail.seatCount}}</span>
            </div>
            <div class="info">
              <section>
                <div class="title">Date</div>
                <div>{{ bookingDetail.showDate}}</div>
              </section>
              <section>
                <div class="title">Time</div>
                <div>{{ bookingDetail.showTime}}</div>
              </section>
              <section>
                <div class="title">Screen</div>
                <div>{{ bookingDetail.screen}}</div>
              </section>
            </div>

            <div class="info">
              <section>
                <div class="title">Location</div>
                <div>{{ bookingDetail.location}}</div>
              </section>

              <section style="margin: 0px">
                <div class="title">Seats</div>
                <div>{{ bookingDetail.seats}}</div>
              </section>
            </div>
          </div>
        </div>

      </div>
      
    </div>
    
    <!-- end row -->
  </div>
  <div class="prebok container">
    <button class="btn-rounded" @click="downloadimage"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#b71ec1" viewBox="0 0 16 16"> <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/> <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/> </svg></button>
  </div>
       </section>
    </div>
  </div>

  <SiteFooter></SiteFooter>
 
</template>

<script>
import axios from "axios";
import { URL } from '@/Conf';
import SiteHeader from "../UI/SiteHeader.vue";
import SiteFooter from "../UI/SiteFooter.vue";
import SiteProgress from "../UI/SiteProgress.vue";
import * as htmlToImage from 'html-to-image';
//import { toPng} from 'html-to-image';
export default {
  components: {
    SiteHeader,
    SiteFooter,
    SiteProgress
  },
  data:()=>({
    imgPath:process.env.BASE_URL,
    isLoaded:false,
    isBderror:false,
    errmsg:null,
    billDeskData:window.billDeskData,
    ticketId:null,
    bookingDetail:{
      movie:null,
      showDate:null,
      showTime:null,
      seats:null,
      seatCount:null,
      screen:null,
      location:null,
      resNo:null,
      qrData:null,
    },
  }),
  methods:{
   
      async downloadimage() {
                /*var container = document.getElementById("image-wrap");*/ /*specific element on page*/
        var that=this;
        //
                var container = document.getElementById("htmltoimage");
                
                htmlToImage.toJpeg(container,{skipAutoScale:true,style:{"font-size": "5px !important","color":"#00ff!important","max-width":"600px"} })
  .then(function (dataUrl) {
    /*var img = new Image();
    img.src = dataUrl;
    document.body.appendChild(img);*/
    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.download = that.bookingDetail.resNo;
                    link.href = dataUrl;
                    link.target = '_blank';
                    link.click();
  })
  .catch(function (error) {
    console.error('oops, something went wrong!', error);
  });
                 /* full page */
                
            },
    
    getDeatails(){
      var posturl,postdata;
      if(this.ticketId){
        posturl=URL+"view-ticket";
        postdata={id:this.ticketId,userId:localStorage.getItem("loginCode")}
      }else{
        posturl=URL + "ticket-status";
        let jsn=JSON.parse(this.billDeskData);
        postdata={trans:jsn.transaction_response,loc:localStorage.getItem("locid")};
      }
        axios.post(posturl,postdata,{headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }}).then((response) => {
          if(response.data.status=="done"){
            this.bookingDetail=response.data.details;
            this.isLoaded=true;
            this.isBderror=false;
          }
          if(response.data.status=="error"){
            this.isBderror=true;
            this.errmsg=response.data.msg;
          }
        });
      
    }
  },
mounted(){
this.ticketId=this.$route.params.id;
if(this.billDeskData||this.ticketId)
{
this.getDeatails();}
},
};
</script>
