<template>
    <section class="pagination" style="padding-bottom: 20px; padding-top: 0px; padding-left: 0px ;padding-right: 0px;">
<button id="pg-button-prev" type="button" class="pagination__button" @click="onClickPreviousPage" v-if="!isInFirstPage">
  <font-awesome-icon icon="fa-solid fa-chevron-left" />
  </button>
<ul class="pagination__list">

    <li v-for="(page,index) in pages" class="pagination__item" :key="index">
      <button 
        type="button" 
        @click="onClickPage(index)"
        :disabled="page.isDisabled"
        :class="{ 'pgbtn active': isPageActive(index),'pgbtn': !isPageActive(index) }"
        :aria-label="`Go to ${page.date.day}`"
        
      >
       {{ page.date.day }}<br>{{ page.date.mth}}
      </button>
    </li>
    
  </ul>
<button id="pg-button-next" type="button" class="pagination__button"  @click="onClickNextPage" v-if="!isInLastPage">
  <font-awesome-icon icon="fa-solid fa-chevron-right" />
  </button>
  

  <!-- <ul class="pagination__list" id="datePager"> <li class="pagination__item"  v-for="(date,index) in dates" :key="date.day"><button :class="{'pgbtn':current-plus!=index,'pgbtn active':current-plus===index}"  data-time="1659176570000" data-position="0" data-target="#20220730">{{ date.day}}<br>{{ date.mth}}</button></li> </ul> -->

  
</section>
</template>
<script>
export default {
data:()=>({
datepage:[],  
}),
props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    dates:{
      type:Object
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 0) {
        return 0;
      }
      if (this.currentPage < this.startPage) { 
        return this.startPage-1;
      }
      if(this.endPage===this.totalPages){
        return this.startPage;
      }
      /*if(this.endPage-this.startPage<this.maxVisibleButtons)
      {
        console.log("inside",this.startPage,this.maxVisibleButtons,this.endPage);
        return Math.max(this.startPage-this.maxVisibleButtons,0);
      }*/
      
      /*if (this.currentPage === this.totalPages) { 
        return this.totalPages - this.maxVisibleButtons + 1;
      }*/

      return this.currentPage-1;

    },
    endPage() {

      return Math.min((this.startPage?this.startPage:0) + this.maxVisibleButtons - 1, this.totalPages);
      
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
      if(this.dates[i]){
              range.push({
                date: this.dates[i] 
              });}
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 0;
    },
    isInLastPage() {
      return this.currentPage+1 === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', this.startPage+page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);

    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);    
    },
    isPageActive(page) {
      return this.currentPage-this.startPage === page;
    },
  }
 
}
</script>
<style>
  .pagination__item {
  width: 51px;
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  align-content: center;
  font-size: 1.1rem;
}.pagination__list {
  list-style-type: none;
}.pagination__item > button {
  width: 60px;
  height: 45px;
  font-size: 9px; color: #000;
}.pagination__item > button, .pagination__button {
  border: none;
  outline: none;
  stroke: none;
  box-shadow: none;
  cursor: pointer;
  border-radius: 9px;
  background: var(--color-gray);
}.pagination__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 350;
  height: 50px;
  border-radius: 9px;
  margin: 0 9px;
  overflow: hidden;
}.pagination, .pagination__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  align-content: center;
}.pagination__item > .active {
background-image: linear-gradient(135deg, rgb(255, 255, 255) 1%,rgb(232, 232, 232) 16%,rgb(223, 223, 223) 30%,rgb(234, 234, 234) 67%,rgb(227, 227, 227) 83%,rgb(255, 255, 255) 100%)!important;
color: #000!important;

}.pagination__button {
  width: 35px;
  height: 35px;
}.pagination__item > button, .pagination__button {
  border: none;
  outline: none;
  stroke: none;
  box-shadow: none;
  cursor: pointer;
  border-radius: 9px;
  background: var(--color-gray);
}
.pagination__item > button, .pagination__button {
  stroke: none;
  cursor: pointer;color: white;
}</style>


