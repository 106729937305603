<template>
     <div :class="siteLoaded?'metaportal_fn_wallet_closer ready':' metaportal_fn_wallet_closer ready active'">
  <div class="loaderWraper"><img :src="imgPath+'assets/img/loader.gif'" width="100"></div>
</div>  
 <SiteProgress></SiteProgress>
    <SiteHeader @locUpdated="locUpdated" ref="headCmp"></SiteHeader>
<IndexBanner ref="bannerComp"></IndexBanner>
  <IndexMovies  ref="hederComp" ></IndexMovies>

<IndexComingSoon ></IndexComingSoon>

<SiteFooter></SiteFooter>



</template>
<script>
import IndexBanner from './IndexBanner.vue';
import IndexMovies from './IndexMovies.vue';
import IndexComingSoon from './IndexComingSoon.vue';
import SiteFooter from '../UI/SiteFooter.vue';
import SiteHeader from '../UI/SiteHeader.vue';
import SiteProgress from '../UI/SiteProgress.vue';


export default {
    data:()=>({
        locChanged:false,
        imgPath:process.env.BASE_URL,
        siteLoaded:true,
    }),
    components:{
        IndexBanner,
        IndexMovies,
        IndexComingSoon,
        SiteFooter,
        SiteHeader,
        SiteProgress
    },
    methods:{
        locUpdated(arg){
            this.locChanged=true;
            this.$refs.hederComp.getMovies();
            this.$refs.bannerComp.locUpdated(arg);
        },
        axloaded(){
            this.siteLoaded=false;
        }

    }
 


}
</script>


