<template>
    <SiteHeader ></SiteHeader>
    <div class="container-fluid small" style="background-color: rgb(255, 255, 255);">
                <div class="metaportal_fn_mint_top row pad50" >
 <section class="content-section" style="padding-top: 10px;
padding-bottom: 0px;">

    <div class="container">
      <div class=" justify-content-center">
       <div class=" row">
       <!-- CSS Ticket inspired by -->

<div class="col-md-6" >
<div>
  
<h3 class="fn__maintitle" style="font-size: 22px;padding-bottom: 20px;">Please contact us</h3>
</div>




  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15657.76490919384!2d75.8473902!3d11.1549308!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65120ffffffff%3A0x25ee16b513c35e80!2sChithra%20Cineplex!5e0!3m2!1sen!2sin!4v1692348233245!5m2!1sen!2sin" width="100%" class="he" style="border:0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> </div>

<div class="col-md-6">

<div>
  
<h3 class="fn__maintitle" style="font-size: 22px;padding-bottom: 8px;">
Send a message</h3>
</div>
<form class="padd10" name="frmTicket" id="frmTicket" @submit.prevent="sendMsg">
<div>
<label class="tecname padtop14">Name *</label>
<input class="fulwid pad7" type="text" v-model="contact.name" >
</div> 

<div>
<label class="tecname padtop14">Email *</label>
<input class="fulwid pad7" type="text" v-model="contact.email" >
</div> 


<div>
<label class="tecname">Mobile No *</label>
<input class="fulwid pad7" type="text" v-model="contact.mobile" >
</div>


<div >
<label class="tecname ">Message * </label>
<textarea class="fulwid pad7" type="textarea" v-model="contact.msg"></textarea>
</div>

<button type="submit" class="btn btn-danger btun marg15 nextBtn bk fulwid" style="padding: 10px;text-transform: uppercase;" :disabled="contact.clicked">{{contact.clicked?'Processing..':'Submit'}} </button>

<div id="msgWrap" v-html="errMsg"></div>
</form>

</div>

<div class="row"  style="padding-top: 40px;">
  <div class="col-xs-12 col-sm-4 col-md-4 pad7" style="padding-bottom: 10px;"><div class="icon-row">
<div class="col">
<span class="circle">
  <font-awesome-icon icon="fa-solid fa-location-pin" class="fontde" aria-hidden="true" />


</span>
</div>
<div class="col" >
<h4 class="no-underline yel foncap" style="font-size: 18px;padding-top: 15px;color: black;">Address</h4>
<p class="white fonsm14"> CHITHRA CINEPLEX<br>
8/567, Mannur Valavu<br>
P.O. Mannur, Kozhikode<br>
 Kerala - 673328 </p>
</div>
</div></div>
  <div class="col-xs-12 col-sm-4 col-md-4 pad7" style="padding-bottom: 10px;"><div class="icon-row">
<div class="col">
<span class="circle">
  <font-awesome-icon icon="fa-solid fa-envelope" class="fontde" aria-hidden="true" />
</span>
</div>
<div class="col" >
<h4 class="no-underline yel foncap" style="font-size: 18px;padding-top: 15px;color: black;">
Email</h4>
<p class="white fonsm14" >chithracineplex@gmail.com</p>
</div>
</div></div>
  <div class="col-xs-12 col-sm-4 col-md-4 pad7" style="padding-bottom: 10px;"><div class="icon-row">
<div class="col">
<span class="circle">
  <font-awesome-icon icon="fa-solid fa-phone" class="fontde" aria-hidden="true" />
</span>
</div>
<div class="col" >
<h4 class="no-underline yel foncap" style="font-size: 18px;padding-top: 15px;color: black;">Telephone</h4>
<p class="white fonsm14" >+91 88480 69869</p>
</div>
</div></div>
  
</div>
        </div>
        
    </div>   
      <!-- end row --> 
    </div>
    <!-- end container --> 
  </section>
                      </div>
                </div>
                <SiteFooter></SiteFooter>
                <div :class="'modal-mask'+(showModal?' show':'')"><div class="modal-wrapper"><div class="modal-container" v-html="modalText"></div></div></div>
</template>

<script>
import axios from "axios";
import { URL } from "@/Conf";
import SiteFooter from '../UI/SiteFooter.vue';
import SiteHeader from '../UI/SiteHeader.vue';
export default {
    components:{
        SiteFooter,
        SiteHeader

    },
    data:()=>({
      showModal:false,
      errMsg:null,
    modalText:null,
    locationId:localStorage.getItem("locid"),
    myloc:null,
    contact:{clicked:false,name:null,email:null,mobile:null,msg:null}
    }),
    methods:{
      
      sendMsg(){
this.errMsg="<div class='text-danger'>";
      var valid=true;
      if (!this.contact.name) {
        valid=false;
        this.errMsg+= 'Please enter your Name<br>';
      }
      if (!this.contact.email) {
        valid=false;
        this.errMsg+= 'Please enter your Email ID<br>';
      } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.contact.email)) {
        this.errMsg+= 'Email ID is not valid<br>';
        valid=false;
      }
      if (!this.contact.mobile) {
        valid=false;
        this.errMsg+= 'Contact number cannot be empty<br>';
      }
      if (!this.contact.msg) {
        valid=false;
        this.errMsg+= 'Please enter your Message<br>';
      }
      this.errMsg+="</div>";
      if(valid)
      {        
this.showModal=true;
this.contact.clicked=true;
  this.modalText="<div class='text-danger'>Please wait</div>";
  var that=this;
  axios.post(URL + "contact",this.contact,{headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              }}).then((response) => {
                this.errMsg="";
                this.contact={clicked:false,name:null,email:null,mobile:null,msg:null};
                if(response.data.msg)
               {
                this.modalText=response.data.msg;
               }
               setTimeout(function () {
                 that.showModal=false;
                 that.modalText=null;
               },3000)
              });
            }
      },
   
      
    }
}
</script>