<template>
        <header class="full-height valign" data-scroll-index="0">
     
        <div class="container-fluid">
            <swiper
    :slides-per-view="1"
    :space-between="0"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    @transitionEnd="beforeTransitionStart"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
    :navigation="false"
    :modules="modules"
    effect="fade"
  >
    <swiper-slide v-for="item in banners" :key="item">
        <img  :src="uploadPath+item.img" :alt="item.txt">
        <!-- <transition name="slide-in-left">
        <div class="channel-logo" v-show="show">
           <img :src="imgPath + 'assets/img/logo-banner.png'" class="c-logo" alt="Savitha Film City" >
           </div>
       </transition> -->
        <div class="banner-text">
    <div style="width:100%">
        <transition name="fadeOne">
    <div v-show="show" class="">
        <h1 class="One big-title" :style=" 'background-image:url('+imgPath+'assets/img/texure.jpg)'  ">{{StripName(item.txt)}}</h1>
      
    </div>
    </transition>
    <transition name="fadeTwo">
    <div v-show="show" class="">
<div class="trending-list">
<div class="d-flex align-items-center mt-2 mt-md-3">
<span class="badge badge-secondary p-2" v-if="item.cert">{{ item.cert}}</span> 
<span class="ml-3">{{ item.dur}}</span>
</div>
<!-- <p class="hiddenxs One" v-if="item.des">{{ item.des}}</p> -->
<div class="hiddenxs" v-if="item.cast">
Starring : <span >{{ item.cast}}
</span>
</div>
<div class="hiddenxs" v-if="item.dir">
Directed by : <span >{{ item.dir}}</span>
</div>
<div class="hiddenxs" v-if="item.lang">
Language  <span >{{getLang(Languages,item.lang)}}</span>
</div>
</div>
    </div>
    </transition>
    <transition name="fadeThree">
        <div>
        <router-link v-show="show" class=" xsfont10 btn btn-hover iq-button font10" v-if="item.slug" :to="'movie/'+item.slug"><font-awesome-icon icon="fa-solid fa-ticket" aria-hidden="true" rotation="45" />
                                   Book Tickets
                                    </router-link>
                                    <a v-show="show" v-if="item.lnk" :href="item.lnk" target="_blank"  class="xsfont10 btn btn-hover iq-button font10"><font-awesome-icon icon="fa-solid fa-bullhorn" aria-hidden="true"  /> More details
                                    </a>
                                </div>
    </transition>
    </div>
        </div>
        <a  class="bannertrail video-open playbtn venobox"
                              data-autoplay="true"
                              data-vbtype="video"
                              v-if="item.trailer"
                              :href="item.trailer"
                              >
                              <svg class="wid15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="80px" height="80px" viewBox="0 0 213.7 213.7" fill="#fff" xml:space="preserve">
                                 <polygon class="triangle" fill="none" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                 <circle class="circle" fill="none" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3">
                                 </circle>
                              </svg>
                              <span class="w-trailor" style="color:#00000003">Watch Trailer</span>
                           </a>
    </swiper-slide>
    
  </swiper>

        </div>

    </header>
    
</template>
<style type="text/css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
    .container-fluid{padding: 0px!important;}
    .channel-name {
    color: #e60b16;
    font-size: 1.25em;
    margin-left: 0.625em;
    letter-spacing: 0.156em;
    font-weight: 500;
}
.bannertrail{position: absolute;bottom: 20px;right: 10px;}
.channel-logo {
    border-left: 0.313em solid #e60b16;
    background: transparent linear-gradient(270deg, #0000 0%, rgba(242, 46, 56, 0.3) 100%);
    padding:0.8em 0.625em 0.625em 0.938em;
    position: absolute;
    overflow: hidden;
    left: 50px; 
    top:120px;
    width: auto;
}
.c-logo{width: auto;}
.banner-text{position: absolute;z-index: 10;top:220px;left:50px;}
.big-title {
    background-repeat: repeat-x;
    background-position: 100% 100%;
    color: transparent;
    -webkit-font-smoothing: antialiased;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing:-1.34688px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.8em;
    font-family: "Oswald", sans-serif !important;
}
.fadeOne-enter-active, .fadeOne-leave-active,.fadeThree-enter-active, .fadeThree-leave-active {
  transition: all 1s ease-out;
}
.fadeOne-enter, .fadeOne-leave-to {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transform: translate(0%, 100%);
}
.fadeThree-enter, .fadeThree-leave-to {
   clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
  transform: translate(100%, 0%);
}

.fadeTwo-enter-active, .fadeTwo-leave-active {
  transition: all .5s ease-out;
}
.fadeTwo-enter, .fadeTwo-leave-to {
  clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
  transform: translate(0%, -100%);
}
.swiper-slide::before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
  width: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;}
.trending-list div {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0.313em;
    color:#fb9926;font-family: "Oswald", sans-serif;
}
.trending-list  span {
    font-size:0.875em;
    font-weight: 400;
    color: #fff;
}

@media(max-width:991px) {
 .channel-logo{display:none;}   
.big-title{
    font-size: 1em; font-family: "Oswald", sans-serif !important;
    }
    .banner-text{top:60px;}
    .trending-list .hiddenxs{display: none;}
}

.slide-in-left-enter-active {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-left-leave-active{
  -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
          animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
}


@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
</style>
<script>
  import 'swiper/css/bundle';
  import 'swiper/css/effect-fade';
  import 'swiper/css/navigation';
import venobox from "venobox";

//import IndexBannerPosters from './IndexBannerPosters.vue';
//import { register } from 'swiper/element/bundle';
 import { EffectFade,Autoplay, Navigation } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { URL,UPLOADS } from '@/Conf';
export default {
     data() {
        return {
            show:true,
            Locationid:1,
            imgPath: process.env.BASE_URL,
            uploadPath:UPLOADS,
            banners:[],
            isLoading:true,
            modules: [Autoplay, Navigation,EffectFade],
            Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
        }
     },

    components:{
        Swiper,
      SwiperSlide,
    },
    computed:{
        StripName : ()=>{
            return (MovieTitle) => {
            return MovieTitle.length>17?MovieTitle.substr(0,17)+"..":MovieTitle;
        }

      },
      getLang : ()=>{
            return (langs,key) => {
            return langs[key]
        }

      }



    },
  methods: {
    beforeTransitionStart(){
     this.show=true;   
    },
    onSlideChange(){
     this.show=false;   
    },
getBanners(){
        axios.get(URL + "banners").then((response) => {
                this.banners = response.data.result;
              }).then(()=>{
                this.isLoading=false;
this.$emit("axloaded");

                new venobox();
              })
        }
  },
  created() {
      /// register();
       this.getBanners();
       
  },
}
</script>
<style>
@import "../../../node_modules/venobox";
</style>