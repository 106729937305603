<template>

<section class="feat-card section-padding" data-scroll-index="1" v-if="movies.length>0" >
<div class="container">



<div class="row">
<div class="col-12 xsnopad10">
<div class="sec-head-bord " style="margin: 0px;">
<div class="container-fluid">
<div class="row">
<div class="col-12 rest flex md-no-flex">
<div class="sec-head ">
<h4 style='color: #f59424;font-weight: 700;font-size: 50px;text-transform: uppercase;font-family: "Oswald", sans-serif;text-align: center;'>Collect Your Tickets Now! </h4>
</div>
</div>
</div>
</div>

</div>
</div>
</div>





<div class="mb-30">
<div class="tabs">
<ul>
<li :class="currentTab==0?'is-active':''"><a @click="currentTab=0" style='font-family: "Oswald", sans-serif;'>Movies</a></li>
<li :class="currentTab==1?'is-active':''"><a @click="currentTab=1" style='font-family: "Oswald", sans-serif;'>Showtime</a></li>
</ul>
</div>
</div>
<div id="movieWrpr" v-if="currentTab==0" class="tab-pane">
<div class="col-md-12">
<div class="row">
<div class="col-4 col-lg-3 col-md-3 xspad pad9" v-for="movie in movies" :key="movie.id">
<div class="item-card  single-shop">
<div class="img shop-img">
<!-- <div href="#" @click="$router.push('movie/'+movie.slug)">

</div> -->
<img :src="path+movie.poster" :alt="movie.title">

<span style="position: absolute;bottom: 35%;left: 0;right: 0;">  <a  class="default-btn venobox venobtn" data-autoplay="true" data-vbtype="video" v-if="movie.trailer" :href="movie.trailer">
<font-awesome-icon icon="fa-solid fa-play"  aria-hidden="true" />
</a> </span>
<span style="position: absolute;bottom: 10px;left: 0;right: 0;">  <a style="position: relative;
bottom: 0;
left: 0;
font-size: 16px;
border-color: #ffffff;
font-weight: 600;
color: #fff !important;
padding: 0px !important;
text-align: left;
margin-left: 13px;"  class="default-btn"  @click="$router.push('movie/'+movie.slug)" href="#">
									
                  {{StripName(movie.title)}}
               </a> </span>
<div class="fav">
<span  :class="certclass[movie.cert]">{{movie.cert}}</span>
</div>


</div> </div>
<div class="item-card md-mb10 cont">

<div class="botm flex" style="margin-top: 0px;">
<div class=" " style="width: 100%;">
<div class="bid font11">
<router-link :to="'movie/'+movie.slug" class="font11" style="width: 100%;"> <i class="fa fa-ticket mr-1" aria-hidden="true"></i> 
Book Tickets
</router-link>

</div>
</div>
</div>
</div>


</div>
</div>
</div>
</div>
<!-- MOVIES ENDS -->
<!-- SHOWTIME -->
<div id="showTimeWrpr" v-if="currentTab==1" class="tab-pane active">
<div class="col-md-12" style="padding-right: 15px;padding-left: 15px;">
<div class="nopad" v-if="!showtimeLoader">
<DatePaginator :totalPages="lastPage" :currentPage="currentPage" @pagechanged="onPageChange" :dates="datePages"></DatePaginator>
</div>
<div v-if="dateMovies">
<div class="row shobg" v-for="(movie,mkey) in dateMovies" :key="mkey">
<div class=" col-md-5 bormain">
<div class="pull-left " style="width: 19%;"><img class="img-responsive bor3" :src="path+'medium/'+movie.poster"  :alt="movie.title" data-pagespeed-url-hash="2433649670" ></div>
<div class="pull-left " style="padding-left: 10px;">
<div class="shohed">{{StripName(movie.title)}}</div>  
<div class=" shola ">{{getLang(Languages,movie.lang)}}<br>{{ movie.dur}}
<br> <span class="shod">{{ movie.diamen}}</span> <span class="shos" >{{ movie.cert}}</span>
</div>
<div class="clearfix"></div>
</div>
</div>
<div class=" col-md-7" v-if="dateShows[mkey]">
<div style="margin-top: 10px;">
<a href="#" class="btbg"  v-for="(show,skey) in dateShows[mkey]" :key="skey" @click="gotoSeatLayout(show.id,show.isonline)"
:class="(!show.isonline ?'':'disabled') +' text-center tibtn'"
:style="$route.params.showid==show.id?{'background-color':'#ffff00'}:{ 'background-color': fillColor(show.booked,show.seats,show.isonline) }">{{ show.stime}}<br> 
<div class="" style="text-transform: uppercase;
font-size: 8px; "> {{ show.screen}} </div> 
<div class="" style="text-transform: uppercase;
font-size: 6px;">{{ show.exp}}</div> 
</a>   
</div>
</div>
</div>

</div>
</div>
</div>
<!-- SHOWTIME ENDS -->

</div>
</section>


</template>

<script>
import venobox from "venobox";
import axios from 'axios';
import { URL,UPLOADS } from '@/Conf';
import DatePaginator from '../MovieDetails/DatePaginator.vue';

export default {
data:()=>({
isLoading:true,
showtimeLoader:true,
currentTab:0,
showModal:false,
warnigText:null,
movies:[],
allShows:[],
dateMovies:[],
dateShows:[],
path:UPLOADS,
certclass:{"U":"ce1","U/A":"ce2","A":"ce3"},
Languages:{"1":"Malayalam",'2':"Tamil",'3':"Hindi",'4':"English",'5':"Telugu",'6':"Kannada"},
days:{"0":"Sun","1":"Mon","2":"Tue","3":"Wed","4":"Thu","5":"Fri","6":"Sat"},
months:{"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"},
currentPage:0,
lastPage:0,
showDates:[],
showTimes:[],
bookedSeats:{},
currentShowtime:[],
datePages:{},
locid:localStorage.getItem("locid")
}),
computed:{
StripName : ()=>{
return (MovieTitle) => {
return MovieTitle.length>17?MovieTitle.substr(0,17)+"..":MovieTitle;
}

},
getLang : ()=>{
return (langs,key) => {
return langs[key]
}

},


},
updated() {

},
components:{DatePaginator},

methods:{
getMovies(){
this.locid=1
if(this.locid)
{axios.get(URL + "movies/"+this.locid).then((response) => {
this.movies = response.data.result;
}).then(()=>{
this.$emit("axloaded");
    
this.isLoading=false;
new venobox();

})}
},
getAllShows(){
axios.get(URL + "allShows").then((response) => {
this.allShows = response.data.result;
this.showDates=Object.keys(response.data.result.dates);
}).then(()=>{
this.isLoading=false;
this.showtimeLoader=false;
this.populatePage();
this.onPageChange(this.currentPage);
this.getBooked();
})
},
getBooked(){
axios.get(URL + "seatcount").then((response) => {
for(var dt in this.allShows.showtime){
for(var mv in this.allShows.showtime[dt]){
for(var sh of this.allShows.showtime[dt][mv]){
var booked=response.data.result.booked[sh.id];
sh["booked"]=booked;
}
}
}

})
},
populatePage(){
this.datePages=[];
this.lastPage=this.showDates.length;
for(var i=0;i<this.lastPage;i++){
var splited=this.showDates[i].split("-");
var pg={"day":this.days[splited[3]]+" "+splited[2],"mth":this.months[splited[1]]}
this.datePages.push(pg); 
}
},
onPageChange(page) {
this.currentPage = page;
var curdt=this.showDates[page];
this.dateMovies=this.allShows.movies[curdt];
this.dateShows=this.allShows.showtime[curdt];
},
gotoSeatLayout(id,isonline){
this.showModal=isonline;
this.warnigText="Specified show tickets not available online.Please visit the box office!!";
if(isonline==0){
this.$router.push("../book-ticket/"+id);
}
},
fillColor(cnt,seats,ison){
if(ison!=0){
return 'rgb(204,204,204)';
}
var blprc=cnt/seats;  
var r = 255,
g = Math.floor((255 * (1-blprc)));
g=g>255?255:g;
var b=g;
return 'rgb('+r+','+g+','+b+')';
}
},
created() {
this.getMovies();
this.getAllShows();
}
}
</script>