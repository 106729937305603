<template>
<div :class="poceedBooking?'metaportal_fn_wallet_closer ready':' metaportal_fn_wallet_closer ready active'">
  <div class="loaderWraper"><img :src="imgPath+'assets/img/loader.gif'" width="100"></div>
</div>  
<div class="col-md-9" style="border-right: 1px solid rgb(181, 181, 181);">
<div class="container-fluid" style="padding : 0px; " >


<div class="" style="position: relative;">

<div class="" style="width: 100%;position: relative;">
<div class="seats col-md-12 nopad  text-center">
<div class="fulwid">
<div class="setxs sc" style="">
<div id="seatWrprOuter" style="" v-if="!seatLoading">


 <div v-for="(cls,index) in seatClass" :key="index">
<div class="seat-wrapper " data-class="" >
<div class="classname">
<h4 class="class-head">{{cls.name}} ₹{{cls.amount}}</h4>
</div>
</div>

<div class="seat-wrapper  cls4" data-class="Silver" v-for="(row,key) in seatLayout[index]" :key="key">
  <button id="st101" @click="clickSeat(seat)" :class="getStyle(seat.empty,seat.typ,seat.bsts,seat.clsts)" :data-id="seat.id"  v-for="(seat,pos) in row" :key="pos">{{setName(seat.name,seat.typ)}}</button>
</div>
</div>
</div>
</div>
</div>
 <div> <img :src="imgPath+'assets/img/ss.svg'" style="width: 45%;" alt="Screen"> </div>
<!-- <div class="screen"> <b>Screen This Way  </b>  </div> -->

<p class="row marg3">
</p>
</div>
</div>
</div>

</div>
<div v-if="!seatLoading" style="display:flex;justify-content: space-between;"><button class="btn-rounded" @click="$router.push('/')"><svg width="25" height="25" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
   viewBox="0 0 38.273 38.273" xml:space="preserve">
<g>
  <path style="fill:#e50914;" d="M20.621,10.484V5.84c0-0.808-0.458-1.548-1.181-1.909c-0.722-0.359-1.589-0.279-2.236,0.206
    l-9.486,7.147c-0.677,0.292-1.117,0.67-1.401,1.058l-5.468,4.119C0.312,16.866-0.003,17.501,0,18.173
    c0.002,0.673,0.322,1.305,0.862,1.706l16.355,12.133c0.646,0.48,1.51,0.554,2.23,0.191c0.72-0.362,1.174-1.1,1.174-1.905v-5.517
    c0.013,0,0.025,0,0.038,0c3.842,0,10.687,1.089,13.366,8.386c0.311,0.846,1.116,1.397,2.001,1.397c0.079,0,0.157-0.004,0.236-0.013
    c0.975-0.108,1.751-0.868,1.88-1.84c0.052-0.394,1.208-9.682-4.461-16.23C30.621,12.948,26.235,10.935,20.621,10.484z
     M20.659,20.515c-1.443,0-2.379,0.132-2.482,0.146c-1.046,0.154-1.822,1.053-1.822,2.111v3.287l-10.66-7.907l3.555-2.678
    c0.136-0.104,0.259-0.222,0.365-0.351c0.155-0.068,0.301-0.152,0.437-0.254l6.303-4.75v2.401c0,1.168,0.939,2.119,2.108,2.134
    c5.345,0.063,9.374,1.61,11.975,4.6c1.442,1.658,2.314,3.602,2.835,5.469C28.923,21.038,23.424,20.515,20.659,20.515z"/>
</g>
</svg></button> <button class="btn-rounded" @click="downloadimage"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#b71ec1" viewBox="0 0 16 16"> <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/> <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/> </svg></button></div>
</div>
<div :class="'modal-mask'+(offline?' show':'')" @click="offline=false;this.$router.push('/')"><div class="modal-wrapper"><div class="modal-container"><h5 class="text-primary text-center">SORRY</h5><div class="text-danger">There is some network issue at this cinema. Please try after some time.</div></div></div></div>
</template>

<script>
import * as htmlToImage from 'html-to-image';
import axios from 'axios';
import { URL } from '@/Conf';
export default {
  props:{
    showId:{default:null}
  },
  data:()=>({
    seatStyles:{"100":"fade","000":"btn-seat","030":"bt-label","020":"btn-seatbook","001":"btn-seatbook","0001":"btn-seat-selected"},
    seatLoading:true,
    response:[],
    seatClass:{},
    seatRows:{},
    seatLayout:{},
    timer:null,
    selectedSeats:[],
    blockedSteats:[],
    imgPath:process.env.BASE_URL,
    poceedBooking:false,
  }),
  methods:{
    getSeatLayout(){
        this.poceedBooking=false;
        this.selectedSeats=[];
        axios.get(URL + "seatLayout/"+this.$route.params.showid).then((response) => {
        this.response = response.data.result;
        this.seatClass=this.response.classes;
        this.seatLayout=this.response.seats;
        this.seatLoading=false;
      }).then(()=>{
        this.getBlockedSeats();
      });
    
    },
    getBlockedSeats(){
        //this.seatLoading=true;
        axios.get(URL + "blockedSeats/"+this.$route.params.showid).then((response) => {
        this.seatLoading=false;
        this.poceedBooking=true;
        if(response.data.sts=="offline"){
        this.offline=true;  
        }
        this.blockedSteats = response.data.result;
        if(this.blockedSteats)
        {let sckeys = Object.keys(this.seatLayout);
                sckeys.forEach((sckey) => {
                let rowseats=this.seatLayout[sckey];
                let rskeys=Object.keys(rowseats); 
                rskeys.forEach((rskey) => {
                  for(let seat of rowseats[rskey]){
                    if(this.selectedSeats.includes(seat.id)){
                    continue;
                    }
                    if(this.blockedSteats.includes(seat.id)){
                      seat.bsts=1;
                    }else{
                      seat.bsts=0;
                    }
                  }
                });
                });}

        //for(let seat of this.seatLayout){
        //}
    })},
clickSeat(itm){
 if(itm.typ!=0 || itm.empty || itm.bsts) {
  return
 }
 if(this.selectedSeats.length>0 && this.selectedSeats[0].clid!=itm.clid){
  return
 }
 if(itm.clsts){
 delete itm["clsts"];
 }else{
 if(this.selectedSeats.length >=10) 
 {
  return
 } 
 itm["clsts"]=1; 
 }
let pos=this.selectedSeats.indexOf(itm);
if(pos!==-1){
this.selectedSeats.splice(pos,1);
}else{
 this.selectedSeats.push(itm) 
}
this.$emit('seatclicked',this.selectedSeats);
},
    getStyle(empty,typ,bsts,clsts=""){
      let key=empty+""+typ+bsts+clsts;
      return this.seatStyles[key];
    },
setName(stname,typ){
return typ==3?stname:stname.replace(/\D/g,'')
    },

    async downloadimage() {
               var container = document.getElementById("seatWrprOuter");
                htmlToImage.toJpeg(container,{skipAutoScale:true,style:{"font-size": "5px !important","color":"#00ff!important","max-width":"600px"} })
  .then(function (dataUrl) {
    /*var img = new Image();
    img.src = dataUrl;
    document.body.appendChild(img);*/
    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.download = "seatlayout";
                    link.href = dataUrl;
                    link.target = '_blank';
                    link.click();
  })
  .catch(function (error) {
    console.error('oops, something went wrong!', error);
  });
                 /* full page */
                
            },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.getSeatLayout();
      }
    )
  },
  mounted(){
    this.getSeatLayout();
    this.timer = setInterval(this.getBlockedSeats,20000)
  },
  unmounted(){
    clearInterval(this.timer);
  },emits:["seatclicked"]
}
</script>