<template>
    <footer class="bg-img pb-0" style="background-color: #3c3c3c;" >
       
       <div class="sub-footer pt-40 pb-40">
           <div class="container">
               <div class="row">
                   <div class="col-lg-6">
                       <div class="horizontal-link fz-13">
                           <ul class="rest">
                           
                               <li class="mr-30">
                                <router-link to="/privacypolicy" style="color: white !important;" >Privacy policy</router-link>
                                  
                               </li>
                               <li class="mr-30">
                                <router-link to="/termofservice" style="color: white !important;">Terms of service</router-link>
                                
                               </li>
                               <li class="mr-30">
                                <router-link to="/refundpolicy" style="color: white !important;">Refund Policy</router-link>
                                
                               </li>
                               
                               <li>
                                <router-link to="/contact" style="color: white !important;">Contact Us</router-link>
                              
                               </li>
                           </ul>
                       </div>
                   </div>
                   <div class="col-lg-6">
                       <div class="copyrights d-flex justify-content-end">
                           <div class="fz-13" style="color: white;">© 2023 Chithra Cineplex is Proudly Powered by <span class="underline"><a
                                     
                                       target="_blank" style="color: #ff103d !important;"> Movieclicks</a></span></div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </footer>
</template>

<script>
export default {
    
}
</script>